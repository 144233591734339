import { Routes, Route } from 'react-router-dom';
import PaginaNaoEncontrada from './components/pagina-nao-encontrada.jsx';
import ResearchForm from './components/research/research-form.jsx';
import { Msg } from './components/Msg.jsx';

function MainRoutes() {
    return (
        <Routes>
            <Route exact path="*" element={ <PaginaNaoEncontrada /> }/>
            <Route exact path="/research/:rest" element={ <ResearchForm /> }/>
            <Route exact path="/research/enviado" element={ <Msg title='Pesquisa de Satisfação' text='Obrigado pela sua avaliação!'/> }/>
            <Route exact path="/research/erro" element={ <Msg title='Erro!' text='Houve um erro ao enviar sua resposta' danger={ true }/> }/>
        </Routes>
    );
}

export default MainRoutes;