// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.msg_container__XEaGe {
    font-family: Garamond, serif;
    width: 50vw;
    height: 300px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
    background-color: #ffffff;
    margin: 40px 0 40px 0;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px;
    justify-content: center;
    font-size: 40px;
    color: #303030;
}

@media only screen and (max-width: 600px) {
    .msg_container__XEaGe { 
        width: 90%; 
        /* height: 80%; */
        margin: 20% 0 10% 0;
        position: absolute;
        left: 50%;
        /* transform: translateX(-50%); */
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/research/msg.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,2BAA2B;IAC3B,mBAAmB;IACnB,yBAAyB;IACzB,qBAAqB;IACrB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI;QACI,UAAU;QACV,iBAAiB;QACjB,mBAAmB;QACnB,kBAAkB;QAClB,SAAS;QACT,iCAAiC;IACrC;AACJ","sourcesContent":[".container {\n    font-family: Garamond, serif;\n    width: 50vw;\n    height: 300px;\n    position: absolute;\n    left: 50%;\n    transform: translateX(-50%);\n    border-radius: 20px;\n    background-color: #ffffff;\n    margin: 40px 0 40px 0;\n    display: flex;\n    align-items: center;\n    text-align: center;\n    padding: 10px;\n    justify-content: center;\n    font-size: 40px;\n    color: #303030;\n}\n\n@media only screen and (max-width: 600px) {\n    .container { \n        width: 90%; \n        /* height: 80%; */\n        margin: 20% 0 10% 0;\n        position: absolute;\n        left: 50%;\n        /* transform: translateX(-50%); */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `msg_container__XEaGe`
};
export default ___CSS_LOADER_EXPORT___;
