import React from 'react';
import msgStyles from '../styles/research/msg.module.css';

export function Msg({ title, text, danger }) {
	const style = danger ? {color:'#f03e3e'} : {};

    document.body.style.backgroundColor = '#303030';
    document.title = 'Erro!';
	document.title = title;

    return (
        <div className={msgStyles.container} style={ style } >{ text }</div>
    );
}